// @flow strict
import * as React from "react";
import videojs, { type VideojsConfig } from "video.js";

// https://docs.videojs.com/tutorial-react.html

// This nested structure is needed solely for the key prop. Whenever the config
// changes, we destroy the player. When the player gets destroyed, the video element
// is destroyed. BUT, React wants to reuse it. So, everything breaks. To fix this, we
// pass in a key to the subplayer, which will change when the config changes, tricking
// React into thinking it's a different element, triggering a remount.

type Props = {|
  +style: { ... },
  +config: VideojsConfig,
|};
export default function VideoPlayer({ config, style }: Props): React.Node {
  return (
    <SubPlayer style={style} config={config} key={JSON.stringify(config)} />
  );
}

function SubPlayer({ config, style }: Props): React.Node {
  const videoRef = React.useRef<null | React.ElementRef<"video">>(null);
  React.useEffect(() => {
    // instantiate Video.js
    if (videoRef.current != null) {
      const player = videojs(
        videoRef.current,
        config,
        function onPlayerReady() {}
      );
      return () => player.dispose();
    }
    return () => {};
  }, [config, style]);

  // wrap the player in a div with a `data-vjs-player` attribute
  // so videojs won't create additional wrapper in the DOM
  // see https://github.com/videojs/video.js/pull/3856{
  return (
    <div>
      <div data-vjs-player style={{ ...style }}>
        <video ref={videoRef} className="video-js"></video>
      </div>
    </div>
  );
}
