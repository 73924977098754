// @flow strict
import * as React from "react";
import debounce from "lodash.debounce";

type Props = {|
  +prelabel?: string,
  +postlabel?: string,
  +value: ?number,
  +onChange: (value: ?number) => mixed,
  +placeholder?: string,
  min?: number,
  max?: number,
  step?: number,
|};
export default function NumberInput({
  prelabel,
  postlabel,
  value,
  onChange,
  placeholder,
  min,
  max,
  step,
}: Props): React.Node {
  const [currentValue, setCurrentValue] = React.useState<?number>(value);

  const debouncedSave = React.useMemo(
    () => debounce((frequency) => onChange(frequency), 500),
    [onChange]
  );

  return (
    <div className="input-group mb-3">
      {prelabel != null && (
        <span className="input-group-text" id="update-freq-label">
          {prelabel}
        </span>
      )}
      <input
        type="number"
        value={currentValue}
        max={max}
        min={min}
        step={step}
        className="form-control"
        placeholder={placeholder}
        onChange={(event: {+target: {+value: ?number, ...}, ...}) => {
          setCurrentValue(event.target.value);
          debouncedSave(event.target.value);
        }}
      />
      {postlabel != null && (
        <span className="input-group-text">{postlabel}</span>
      )}
    </div>
  );
}
