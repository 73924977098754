// @flow strict

import * as React from "react";
import VideoPlayer from "./VideoPlayer";
import DriverCameraList from "./DriverCameraList";
import RaceAudioList from "./RaceAudioList";
import ScoringPylon from "./ScoringPylon";
import "./App.css";

function App(): React.Node {
  const videoJsOptions = {
    autoplay: "muted",
    controls: true,
    liveui: true,
    liveTracker: {
      trackingThreshold: 0,
    },
    sources: [
      {
        src: "https://admdn3ta.cdn.mgmlcdn.com/adsports3/smil:adsports3.stream.smil/chunklist_b1800000_t64NzIwcA==.m3u8",
      },
    ],
  };

  return (
    <div
      className="container-fluid mt-1"
      style={{display: "flex", flexDirection: "row", flexWrap: "wrap-reverse"}}
    >
      <section style={{minWidth: 300}}>
        <ScoringPylon />
      </section>
      <section
        style={{
          display: "flex",
          flexDirection: "column",
          // width: "auto",
          flexGrow: 1,
          minWidth: "75%",
        }}
      >
        <section className="mb-2">
          <VideoPlayer style={{width: "100%", paddingTop: "56.25%"}} config={videoJsOptions} />
        </section>
        <section
          style={{display: "flex", flexDirection: "row", flexWrap: "wrap"}}
        >
          <div style={{flexGrow: 1}} className="me-1 mb-1">
            <DriverCameraList />
          </div>
          <div style={{flexGrow: 1}} className="mb-1">
            <RaceAudioList />
          </div>
        </section>
      </section>
    </div>
  );
}

export default App;
