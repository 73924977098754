// @flow strict

import * as React from "react";
import invariant from "invariant";

import NumberInput from "./bootstrap/NumberInput";

const MINIMUM_FREQ_FOR_UPDATE = 500;

type FeedItem = {
  +delta: string,
  +driver: {+full_name: string, ...},
  +vehicle_number: number,
  ...
};
type RaceData = {
  +run_name: string,
  +laps_in_race: number,
  +lap_number: number,
  +flag_state: number,
  +stage: ?{+finish_at_lap: number, stage_num: number, ...},
  ...
};
export default function ScoringPylon(): React.Node {
  const [updateFrequencyInSeconds, setUpdateFrequencyInSeconds] =
    React.useState<number>(1000);
  const [feed, setFeed] = React.useState<?$ReadOnlyArray<FeedItem>>(null);
  const [raceData, setRaceData] = React.useState<?RaceData>(null);
  const [hasData, setHasData] = React.useState<?boolean>(null);

  const realUpdateFrequency = Math.max(
    updateFrequencyInSeconds,
    MINIMUM_FREQ_FOR_UPDATE
  );
  React.useEffect(() => {
    let intervalId = null;
    if (hasData !== false) {
      intervalId = setInterval(() => {
        console.log("updating", realUpdateFrequency);
        fetch("https://cf.nascar.com/live/feeds/live-feed.json")
          .then((response) => response.json())
          .then(({vehicles, ...latestRaceData}) => {
            setFeed(vehicles);
            setRaceData(latestRaceData);
            setHasData(vehicles != null);
          });
      }, realUpdateFrequency);
    }

    return () => {
      if (intervalId != null) {
        clearInterval(intervalId);
      }
    };
  }, [realUpdateFrequency, hasData]);
  if (hasData == null) {
    return "Loading...";
  } else if (hasData != null && feed == null) {
    return "No active race. Not updating.";
  }

  invariant(
    feed != null && raceData != null && raceData.stage != null,
    "everything must be present if data is present"
  );
  return (
    <aside>
      <h3>{raceData.run_name}</h3>
      <div>
        L{raceData.lap_number}/{raceData.laps_in_race} | S
        {raceData.stage.stage_num} (ends L{raceData.stage.finish_at_lap}){" "}
        <Flag flagState={raceData.flag_state} />
      </div>
      <table className="table table-sm">
        <tbody>
          {feed.map(
            (
              {delta, driver: {full_name: driverName}, vehicle_number},
              index
            ) => (
              <tr key={vehicle_number}>
                <td>
                  <small>{index + 1}</small>
                </td>
                <td>
                  <small>#{vehicle_number}</small>
                </td>
                <td>
                  <small>{driverName}</small>
                </td>
                <td style={{textAlign: "right"}}>
                  <small>
                    {delta === "0"
                      ? "-"
                      : parseInt(delta) < 0
                      ? delta
                      : precise(delta)}
                  </small>
                </td>
              </tr>
            )
          )}
        </tbody>
      </table>
      <NumberInput
        prelabel="Freq."
        postlabel="ms"
        min={0}
        step={100}
        value={updateFrequencyInSeconds}
        onChange={(value) =>
          setUpdateFrequencyInSeconds(
            value == null ? MINIMUM_FREQ_FOR_UPDATE : value
          )
        }
      />
      {updateFrequencyInSeconds < MINIMUM_FREQ_FOR_UPDATE && (
        <p>Update frequency is too low. Using {MINIMUM_FREQ_FOR_UPDATE}</p>
      )}
    </aside>
  );
}

function precise(x: string, precision: number = 3): string {
  return Number.parseFloat(x).toFixed(precision);
}

function Flag({flagState}: {|+flagState: number|}): React.Node {
  if (flagState === 1) {
    return "🟩";
  } else if (flagState === 2) {
    return "🟨";
  }
  return null;
}
