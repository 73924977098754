// @flow strict
import * as React from "react";
import SelectableList from "./bootstrap/SelectableList";
import VideoPlayer from "./VideoPlayer";

type Feed = {
  +id: number,
  +firstName: string,
  +lastName: string,
  +title: string,
  +sources: $ReadOnlyArray<{+URL: string, ...}>,
  ...
};

export default function DriverCameraList(): React.Node {
  const [feeds, setFeeds] = React.useState<$ReadOnlyArray<Feed>>([]);
  const [selectedFeed, setSelectedFeed] = React.useState<?Feed>(null);
  const videoJsOptions = selectedFeed && {
    autoplay: "muted",
    controls: true,
    liveui: true,
    liveTracker: {
      trackingThreshold: 0,
    },
    sources: [
      {
        src: selectedFeed.sources[0].URL,
      },
    ],
  };

  React.useEffect(() => {
    fetch("https://cf.nascar.com/drive/configs.json")
      .then((response) => response.json())
      .then(({data: {videos}}) => setFeeds(videos));
  }, []);

  return (
    <div style={{display: "flex", flexDirection: "row"}}>
      <div className="me-1">
        <SelectableList
          options={
            feeds.length
              ? feeds.map(({firstName, lastName, id, title}) => ({
                  key: id.toString(),
                  display: firstName.length > 0 ? `${firstName} ${lastName}`: title,
                }))
              : [{key: "loading", display: "Loading..."}]
          }
          selectedKey={selectedFeed?.id.toString()}
          onSelect={(feedKey) =>
            setSelectedFeed(feeds.find(({id}) => id.toString() === feedKey))
          }
        />
      </div>
      {videoJsOptions != null && (
        <VideoPlayer style={{height: "100%"}} config={videoJsOptions} />
      )}
    </div>
  );
}
