// @flow strict

import * as React from "react";

// Options should be a Array<{key: string, display: string}>, selectedKey is optional
type Props<DisplayType: React.Node> = {|
  +options: $ReadOnlyArray<{+key: string, +display: DisplayType, ...}>,
  +selectedKey: ?string,
  +onSelect: (key: string) => mixed,
|};
export default function SelectableList<DisplayType: React.Node>({
  options,
  selectedKey = null,
  onSelect = () => {},
}: Props<DisplayType>): React.Node {
  return (
    <div className="list-group">
      {options.map(({key, display}) => (
        <button
          href="#"
          className={`button list-group-item-action list-group-item ${
            key === selectedKey ? "active" : ""
          }`}
          key={key}
          onClick={() => onSelect(key)}
        >
          {display}
        </button>
      ))}
    </div>
  );
}
