// @flow strict
import * as React from "react";
import VideoPlayer from "./VideoPlayer";
import Select from "./bootstrap/Select";

type Feed = {
  +base_url: string,
  +stream_ios: string,
  +driver_name: string,
  +driver_number: number,
  ...
};

export default function RaceAudioList(): React.Node {
  const [series, setSeries] = React.useState("cup");
  const [feeds, setFeeds] = React.useState<$ReadOnlyArray<Feed>>([]);
  const [selectedFeed, setSelectedFeed] = React.useState<?Feed>(null);
  const videoJsOptions = selectedFeed && {
    autoplay: true,
    controls: true,
    liveui: true,
    liveTracker: {
      trackingThreshold: 0,
    },
    sources: [
      {
        src: `${selectedFeed.base_url}${selectedFeed.stream_ios}`,
      },
    ],
  };
  React.useEffect(() => {
    const seriesId = { cup: 1, xfinity: 2, trucks: 3 }[series];
    fetch(`https://cf.nascar.com/config/audio/audio_mapping_${seriesId}_3.json`)
      .then((response) => response.json())
      .then(({ audio_config }) => setFeeds(audio_config));
  }, [series]);

  return (
    <div style={{ display: "flex", flexDirection: "column" }}>
      <div className="form-check">
        <input
          className="form-check-input"
          type="radio"
          name="flexRadioDefault"
          id="flexRadioDefault1"
          onClick={() => setSeries("cup")}
          checked={series === "cup" ? true : false}
        />
        <label className="form-check-label" htmlFor="flexRadioDefault1">
          Cup
        </label>
      </div>
      <div className="form-check">
        <input
          className="form-check-input"
          type="radio"
          name="flexRadioDefault"
          id="flexRadioDefault1"
          onClick={() => setSeries("xfinity")}
          checked={series === "xfinity" ? true : false}
        />
        <label className="form-check-label" htmlFor="flexRadioDefault1">
          Xfinity
        </label>
      </div>
      <div className="form-check">
        <input
          className="form-check-input"
          type="radio"
          name="flexRadioDefault"
          id="flexRadioDefault1"
          onClick={() => setSeries("trucks")}
          checked={series === "trucks" ? true : false}
        />
        <label className="form-check-label" htmlFor="flexRadioDefault1">
          Trucks
        </label>
      </div>
      <form className="me-1 mb-1">
        <Select
          options={
            feeds.length
              ? feeds.map(({ driver_name, driver_number, stream_ios }) => ({
                  key: stream_ios,
                  display: isInt(driver_number)
                    ? `#${driver_number} ${driver_name}`
                    : driver_name,
                }))
              : [{ key: "loading", display: "Loading..." }]
          }
          selectedKey={selectedFeed?.stream_ios}
          onSelect={(feedKey) =>
            setSelectedFeed(
              feeds.find(({ stream_ios }) => stream_ios === feedKey)
            )
          }
        />
      </form>
      {videoJsOptions != null && (
        <VideoPlayer
          style={{ height: "30px", minWidth: "100%" }}
          config={videoJsOptions}
        />
      )}
    </div>
  );
}

function isInt(s) {
  return !isNaN(parseInt(s, 10));
}
