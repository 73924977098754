// @flow strict
import * as React from "react";

type Props<DisplayType: React.Node> = {|
  +options: $ReadOnlyArray<{+key: string, +display: DisplayType, ...}>,
  +selectedKey?: ?string,
  +onSelect: (key: string) => mixed,
|};
export default function Select<DisplayType: React.Node>({
  options,
  selectedKey = null,
  onSelect = () => {},
}: Props<DisplayType>): React.Node {
  return (
    <select
      className="form-select"
      onChange={(event: {
        +target: {+selectedOptions: $ReadOnlyArray<{+value: string, ...}>, ...},
        ...
      }) => {
        const selectedKey = options.find(
          ({display}) => display === event.target.selectedOptions[0].value
        )?.key;
        if (selectedKey != null) {
          onSelect(selectedKey);
        }
      }}
    >
      <option disabled selected={selectedKey == null ? undefined : true}>
        Select...
      </option>
      {options.map(({key, display}) => (
        <option key={key} selected={key === selectedKey ? true : undefined}>
          {display}
        </option>
      ))}
    </select>
  );
}
